<template>
    <div class="rankTop">
        <div class="topContent">
            <div @click="selectHandle" class="yearMonth">{{year}}年{{month}}月 <van-icon style="transform:scale(0.8)" size="8px" name="arrow-down" /></div>
            <div v-if="textList.length > 0" class="tipUser" ref="tipUser">
                <img :src="textList[count] && textList[count].avatarUrl" alt="">{{textList[count] && textList[count].userNicker}}在
                <span style="color:#FE4300">{{textList[count] && textList[count].beforeTime}}</span>获得佣金
                <span style="color:#FE4300">{{textList[count] && textList[count].commissionAmount}}</span>元
            </div>
            <div class="top3">
                <div class="center">
                    <div class="second">
                        <img class="crown" src="../../sprite/crown-icon2.png" alt="">
                        <img class="head" :src="topList[1] && topList[1].avatarUrl" alt="">
                        <img class="rankNum" src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/658f0d5157754bdd8e475218e7f5fb89.png" alt="">
                        <span class="money"><b>{{topList[1] && topList[1].saleAmountShort}}</b><span v-if="topList[1] && topList[1].saleAmount>10000">万</span>元</span>
                        <span class="name">{{topList[1] && topList[1].userNicker}}</span>
                    </div>
                    <div class="first">
                        <img class="crown" src="../../sprite/crown-icon1.png" alt="">
                        <img class="head" :src="topList[0] && topList[0].avatarUrl" alt="">
                        <img class="rankNum" src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/8596a2805a2f4a6ab03df49ff157e5c5.png" alt="">
                        <span class="money"><b>{{topList[0] && topList[0].saleAmountShort}}</b><span v-if="topList[0] && topList[0].saleAmount>10000">万</span>元</span>
                        <span class="name">{{topList[0] && topList[0].userNicker}}</span>
                    </div>
                    <div class="third">
                        <img class="crown" src="../../sprite/crown-icon3.png" alt="">
                        <img class="head" :src="topList[2] && topList[2].avatarUrl" alt="">
                        <img class="rankNum" src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/7499226c49224bf6a214ba27c3ed281e.png" alt="">
                        <span class="money"><b>{{topList[2] && topList[2].saleAmountShort}}</b><span v-if="topList[2] && topList[2].saleAmount>10000">万</span>元</span>
                        <span class="name">{{topList[2] && topList[2].userNicker}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="table">
            <div class="tableTitle">
                <div>昵称</div>
                <div>佣金</div>
            </div>
            <div class="bodyIn">
                <div class="tableBody" v-for="item,index in list" :key="index">
                    <div class="userInfo">
                        <img class="headImg" :src="item.avatarUrl" alt="">
                        <span class="name">{{item.userNicker}}</span>
                        <span class="rankNum">{{item.saleRank}}</span>
                    </div>
                    <div v-if="item.saleAmount > 10000" class="money">{{item.saleAmountShort}}万元</div>
                    <div v-else class="money">{{item.saleAmountShort}}元</div>
                </div>
                <div class="rankTip">
                    <p>排行榜依据实时计算得出，展示所选时间的佣金数据，仅展示前30名，数据更新可能有延迟</p>
                </div>
            </div>
        </div>
        <!-- <div class="goHome" @click="$router.push('/')">去首页</div> -->
        <div class="myRank" v-if="token">
            <div class="myInfo">
                <img :src="avatarUrl" alt="">
                <div class="rankNum">
                    <span>我的排名：{{myRank==0?'未上榜':myRank}}</span>
                    <span class="money">佣金：¥{{myMoney}}</span>
                </div>
            </div>
            <div class="Btn" @click="$router.push('/')">去冲榜</div>
        </div>

        <van-popup v-model="show" position="bottom">
            <div>
                <van-datetime-picker
                    v-model="currentDate"
                    type="year-month"
                    title="选择月份"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @cancel="cancelHandle"
                    @confirm="confirmHandle"
                />
            </div>
        </van-popup>
    </div>
</template>
<script>
import dayjs from 'dayjs'
import { Toast } from 'vant';
import { GetUserCommissionRankList } from '../../service/rankTop'
import { GetUserCommissionRotation} from '../../service/home'
import './index.less'
import { getToken, MGTVVIP_page_view } from '../../utils/tokenUtils'
export default {
    data() {
        return {
            token:localStorage.getItem('token'),
            mgUuid: getToken('mgUuid'),
            year:dayjs().year(),
            month:dayjs().month()+1,
            show:false,
            minDate: new Date(2023, 4, 1),
            maxDate: new Date(dayjs().year(), dayjs().month()),
            currentDate: new Date(dayjs().year(), dayjs().month()),
            topList:[],
            list:[],
            myRank:'',
            myMoney:'',
            avatarUrl:localStorage.getItem('avatarUrl'),
            userNicker:localStorage.getItem('nickName'),
            timer:null,
            textList:[],
            count:0,
            textShow:1,
        }
    },
    created(){
    },
    mounted(){
        this.token = localStorage.getItem('token')
        this.getUserCommissionRankList();
        this.getUserCommissionRotation()
    },
    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }
            return val;
        },
        selectHandle(){
            this.show = true;
        },
        cancelHandle(){
            this.show = false;
        },
        confirmHandle(e){
            console.log(dayjs(e).format('YYYY-MM'))
            this.year = dayjs(e).year()
            this.month = dayjs(e).month()+1
            this.show = false;
            this.getUserCommissionRankList();
        },
        getUserCommissionRankList(){
            GetUserCommissionRankList({
                dateMonth:this.month,
                dataYear:this.year
            }).then((res)=>{
                console.log(res)
                const { code,data } = res;
                if(code == '0'){
                    console.log(data);
                    this.myRank = data.mySaleRank;
                    this.myMoney = data.mySaleAmount;
                    data.saleRanks.forEach((item)=>{
                        if(item.saleAmount > 10000){
                            item.saleAmountShort = (item.saleAmount/10000).toFixed(2)
                        }else{
                            item.saleAmountShort = item.saleAmount
                        }
                    })
                    if(data.saleRanks.length > 0){
                        this.topList = data.saleRanks.slice(0,3)
                        this.list = data.saleRanks.slice(3)
                    }
                    
                }
            })
        },
        handleTipText(){
        this.timer = setTimeout(()=>{
            this.count++
            if(this.textList.length <= this.count){
            this.count = 0;
            }
            this.$nextTick(()=>{
            setTimeout(()=>{
                this.$refs.tipUser.style.opacity = 0;
            },2500)
            })
            this.$refs.tipUser.style.opacity = 1;
            this.handleTipText();
        },4000)
        },
        getUserCommissionRotation(){
            GetUserCommissionRotation().then((res)=>{
                const { code,data } = res
                if(code == '0'){
                console.log(data);
                this.$nextTick(()=>{
                    data.forEach((item)=>{
                    item.beforeTime = dayjs(dayjs(item.orderTime)).diff(dayjs(new Date()), 'hour');
                    if(!item.beforeTime){
                        if(dayjs(dayjs(item.orderTime)).diff(dayjs(new Date()),'minute') == 0){
                            item.beforeTime = '刚刚'
                        }else{
                            item.beforeTime = Math.abs(dayjs(dayjs(item.orderTime)).diff(dayjs(new Date()), 'minute')) +'分钟前';
                        }
                    }else{
                        item.beforeTime = Math.abs(item.beforeTime)+'小时前'
                    }
                    })
                    this.textList = data;
                    if(this.textList.length > 0){
                        this.handleTipText()
                    }
                })
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>

</style>